// Farben
$gruen: #b0c52f;
$blau: #6699cd;
$rot: #8c1815;

// Änderung der Bootstrap-Standardfarben
$primary: $blau;
$danger: $rot;
$success: $gruen;

$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1500px,
    xxxl: 1750px,
);
